// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import modernizr from 'modernizr'

import Rails from "@rails/ujs"
import Spotlight from "spotlight.js/src/js/spotlight"
// import Turbolinks from "turbolinks"
// import "channels"

// You can specify which plugins you need
import { Toast } from "bootstrap"

document.addEventListener("DOMContentLoaded", () => {
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map((toastEl) => {
    return new Toast(toastEl)
  })
  toastList.forEach(toast => toast.show())

  var tab_hash = window.location.hash
  if (tab_hash) {
    var tabEl = document.querySelector(`${tab_hash}-tab`)
    if (tabEl) {
      var tabTrigger = new Tab(tabEl)
      tabTrigger.show()
    }
  }
});

const setIndicators = (homeCarousel, next_slide) => {
  // next_slide is null based
  let lis = homeCarousel.querySelectorAll('.carousel-indicators li')
  lis.forEach((li, index) => {
    if (next_slide === 0) {
      var min = 0
      var max = 2
    } else if (next_slide+1 === lis.length) {
      var min = next_slide - 2
      var max = next_slide
    } else {
      var min = next_slide - 1
      var max = next_slide + 1
    }
    if (index >= min && index <= max) {
      li.classList.remove('visually-hidden')
    } else {
      li.classList.add('visually-hidden')
    }
    // mobile
    if (index == next_slide) {
      li.classList.remove('mobile-hidden')
    } else {
      li.classList.add('mobile-hidden')
    }
  })
}

document.addEventListener("DOMContentLoaded", () => {
  const homeCarousel = document.getElementById('homeCarousel')

  if (homeCarousel) {
    homeCarousel.addEventListener('slide.bs.carousel', (event) => {
      setIndicators(homeCarousel, event.to)
    })
    setIndicators(homeCarousel, 0)
  }
})

document.addEventListener('DOMContentLoaded', () => {
  let top_button = document.getElementById('back-to-top')
  let header = document.querySelector('header')

  window.addEventListener('scroll', () => {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      header.classList.add('scrolled')
      top_button.classList.remove('invisible')
    } else {
      header.classList.remove('scrolled')
      top_button.classList.add('invisible')
    }
  })
  top_button.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  })

  // Damit er beim neu laden wenn schon gescrollt ist die class setzt.
  window.scrollBy(0,0)
})

import 'bootstrap-icons/font/bootstrap-icons.css'

import "../stylesheets/application"

const images = require.context('../images', true)
const fonts = require.context('../fonts', true)

Rails.start()
// Turbolinks.start()

import "controllers/frontend"
// import "packs/common"
